export default [
  {
    path: '/admin/domiciliary',
    name: 'admin-domiciliary',
    component: () => import('@/views/admin/domiciliaries/Domiciliary.vue'),
    meta: {
      resource: 'roles.view',
      action: 'read',
      pageTitle: 'Domicilios',
      breadcrumb: [
        {
          text: 'Domicilios',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/domiciliary/history',
    name: 'admin-domiciliary-history',
    component: () => import('@/views/admin/domiciliaries/History.vue'),
    meta: {
      resource: 'roles.view',
      action: 'read',
      pageTitle: 'Domicilios',
      breadcrumb: [
        {
          text: 'Domicilios',
          active: true,
        },
      ],
    },
  },
  {
    path: "/admin/domiciliary/:id",
    name: "admin-domiciliary-detail",
    component: () => import("@/views/admin/domiciliaries/DomiciliaryDetail.vue"),
    meta: {
      resource: "deliveries.view",
      action: "read",
      pageTitle: "",
      breadcrumb: [
        {
          text: 'Domicilios',
          active: false,
          to: '/admin/domiciliary',
        },
        {
          text: 'Detalle de domicilio',
          active: true,
        }
      ],
    },
  },
]